import React, { useState } from "react";

const CreditCardDetails = ({ formData, handleInputChange, error }) => {
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal

  const handleModalClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <h5 className="mb-4">Shipping Information</h5>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="AddressLine1"
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleInputChange}
              placeholder="Address Line 1"
              required={true}
            />
            <label htmlFor="AddressLine1">
              Address Line 1 <span className="required">*</span>
            </label>
            {error?.addressLine1 && (
              <p className="form-error">{error?.addressLine1}</p>
            )}
          </div>
        </div>
        {/* end col */}

        <div className="col-lg-12">
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="AddressLine2"
              name="addressLine2"
              value={formData.addressLine2}
              onChange={handleInputChange}
              placeholder="Address Line 2"
            />
            <label htmlFor="AddressLine2">Address Line 2</label>
          </div>
        </div>
        {/* end col */}

        <div className="col-lg-6">
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="City"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
              required={true}
            />
            <label htmlFor="City">
              City <span className="required">*</span>
            </label>
            {error?.city && <p className="form-error">{error?.city}</p>}
          </div>
        </div>
        {/* end col */}

        <div className="col-lg-6">
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="Province"
              name="province"
              value={formData.province}
              onChange={handleInputChange}
              placeholder="Province"
              required={true}
            />
            <label htmlFor="Province">
              Province <span className="required">*</span>
            </label>
            {error?.province && <p className="form-error">{error?.province}</p>}
          </div>
        </div>
        {/* end col */}

        <div className="col-lg-6">
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="PostalCode"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleInputChange}
              placeholder="Postal Code"
              required={true}
            />
            <label htmlFor="PostalCode">
              PostalCode <span className="required">*</span>
            </label>
            {error?.postalCode && (
              <p className="form-error">{error?.postalCode}</p>
            )}
          </div>
        </div>
        {/* end col */}

        <div className="col-lg-6">
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="Country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              placeholder="Country"
              required={true}
            />
            <label htmlFor="Country">
              Country <span className="required">*</span>
            </label>
            {error?.country && <p className="form-error">{error?.country}</p>}
          </div>
        </div>
        {/* end col */}

        <div className="col-lg-12">
          <div className="form-check align-items-center d-flex mb-3">
            <input
              className="form-check-input mt-0"
              type="checkbox"
              id="AcceptT&C"
              name="acceptTerms"
              checked={formData.acceptTerms}
              onChange={handleInputChange}
            />
            <label
              className="form-check-label text-muted ms-2"
              htmlFor="AcceptT&C"
            >
              I Accept{" "}
              <a
                href="#"
                className="text-primary"
                data-bs-toggle="modal"
                data-bs-target="#termsAndConditions"
              >
                Terms And Condition
              </a>
            </label>
          </div>
          {error?.acceptTerms && (
            <p className="form-error">{error?.acceptTerms}</p>
          )}
        </div>
        {/* end col */}

        <div className="col-lg-12">
          <button className="btn btn-primary rounded-md w-100" type="submit">
            Sign Up
          </button>
        </div>
        {/* end col */}

        
      </div>


        {/* Modal */}
        <div
          className="modal fade"
          id="termsAndConditions"
          aria-hidden="true"
          aria-labelledby="conditions"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content border-0 shadow-md rounded-md">
              <div className="modal-header">
                <h5 className="modal-title" id="conditions">
                  Terms and Conditions
                </h5>
                <button
                  type="button"
                  className="btn btn-close"
                  data-bs-dismiss="modal"
                  id="close-modal"
                >
                  <i className="uil uil-times fs-4"></i>
                </button>
              </div>
              <div className="modal-body">
              <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Introduction:</strong></h5>
                    <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>Welcome to our silent auction website.</li>
                      <li style={{ fontSize: '14px' }}>By participating, users agree to comply with these terms.</li>
                    </ul>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Registration:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Users must register to participate.</li>
                      <li style={{ fontSize: '14px' }}>b. Provide accurate information during registration.</li>
                      <li style={{ fontSize: '14px' }}>c. Keep login credentials confidential.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Bidding:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Bids are binding commitments to purchase.</li>
                      <li style={{ fontSize: '14px' }}>b. Highest bid at the auction's close wins.</li>
                      <li style={{ fontSize: '14px' }}>c. Incremental bidding may apply.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Payment:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Winners must complete payment promptly.</li>
                      <li style={{ fontSize: '14px' }}>b. Accepted payment methods specified on the site.</li>
                      <li style={{ fontSize: '14px' }}>c. Failure to pay may result in consequences.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Item Descriptions:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Item descriptions are accurate to the best of our knowledge.</li>
                      <li style={{ fontSize: '14px' }}>b. No warranty or guarantee on the items.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Auction Duration:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Clearly stated start and end times.</li>
                      <li style={{ fontSize: '14px' }}>b. We reserve the right to extend or terminate auctions.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Reserve Prices:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Some items may have reserve prices.</li>
                      <li style={{ fontSize: '14px' }}>b. The auctioneer has the right to disclose or waive reserves.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Winning Notifications:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Winners will be notified via email.</li>
                      <li style={{ fontSize: '14px' }}>b. Failure to respond within a specified period may result in disqualification.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Disputes:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Contact us for dispute resolution.</li>
                      <li style={{ fontSize: '14px' }}>b. Our decision on disputes is final.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>User Conduct:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Respectful and lawful behavior is expected.</li>
                      <li style={{ fontSize: '14px' }}>b. Any form of manipulation, fraud, or interference is prohibited.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Site Availability:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. We strive for continuous availability but may experience downtime.</li>
                      <li style={{ fontSize: '14px' }}>b. Not responsible for losses due to website unavailability.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Privacy:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Refer to our privacy policy for information handling.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Intellectual Property:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Users retain intellectual property rights in their content.</li>
                      <li style={{ fontSize: '14px' }}>b. Users grant us a license to display and promote items.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Termination:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. We may terminate accounts for violations.</li>
                      <li style={{ fontSize: '14px' }}>b. Users may terminate their accounts at any time.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Modification of Terms:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. We reserve the right to modify these terms.</li>
                      <li style={{ fontSize: '14px' }}>b. Users will be notified of changes.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Governing Law:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. These terms are governed by British Columbia, Canada law.</li>
                      <li style={{ fontSize: '14px' }}>b. Any legal disputes will be resolved in the appropriate jurisdiction.</li>
                    </ol>
                  </li>
                  <li style={{ fontSize: '14px' }}>
                    <h5><strong>Contact Information:</strong></h5>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      <li style={{ fontSize: '14px' }}>a. Contact us for inquiries or assistance.</li>
                    </ol>
                  </li>
                </ol>
              <p style={{ fontSize: '14px' }}>By using our silent auction website, users agree to abide by these terms and conditions.</p>


              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default CreditCardDetails;