import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Countdown from "react-countdown";
import "../../assets/css/Login.css";
import { client01, client14, wine1 } from "../../components/imageImport";

const ItemDetailOne = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const item_id = queryParams.get("item_id");

  const [title, setTitle] = useState("");
  const [image_url, setImage_url] = useState("");
  const [bids, setBids] = useState([]);
  const [ItemDescription, setItemDescription] = useState("");
  const [owner, setOwner] = useState("");
  const [MinimumBiddingIncrement, setMinimumBiddingIncrement] = useState(0);
  const [MinimumBid, setMinimumBid] = useState(0);
  const [BuyNowPrice, setBuyNowPrice] = useState(0);
  const [bidSuccess, setBidSuccess] = useState(true);
  const [formData, setFormData] = useState({});

  const countdown = new Date("2024-04-10T00:00:00");


  const getImageUrl = async (filename) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/brenda/auction-items/file/${filename}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch auction items");
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error("Error fetching auction items:", error);
    }
  };

  const getBidsByItem = async (item_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/bids?item_id=${item_id}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch auction items");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching auction items:", error);
    }
  };

    // Fetch auction items from the API
    const updateBids = async () => {
        const bids = await getBidsByItem(item_id)

        setBids(bids);


        const getMinBid = (bids, min_increment, min_bid) => {
          if (bids && bids.length > 0) {
            return bids[0].bid_amount + min_increment;
          } else {
            return min_bid;
          }
        };

        const min_bid = getMinBid(bids, MinimumBiddingIncrement, MinimumBid);

        setFormData({
          bid_amount: min_bid,
          item_id: item_id,
          min_bid_amount: min_bid
        });
    };


  useEffect(() => {
    // Fetch auction items from the API
    const fetchAuctionItems = async (item_id) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/brenda/auction-items?item_id=${item_id}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch auction items");
        }

        const record = await response.json();

        const imageUrl = await getImageUrl(record.Image1); // Assuming Image1 is the property containing the filename
        const bids = await getBidsByItem(item_id)

        setBids(bids);
        setImage_url(imageUrl);
        setTitle(record.title);
        setItemDescription(record.ItemDescription);
        setOwner(record.owner);
        setMinimumBid(record.MinimumBid);
        setMinimumBiddingIncrement(record.MinimumBiddingIncrement);
        setBuyNowPrice(record.BuyNowPrice);

        const getMinBid = (bids, min_increment, min_bid) => {
          if (bids && bids.length > 0) {
            return bids[0].bid_amount + min_increment;
          } else {
            return min_bid;
          }
        };

        const min_bid = getMinBid(bids, record.MinimumBiddingIncrement, record.MinimumBid);

        setFormData({
          bid_amount: min_bid,
          item_id: item_id,
          min_bid_amount: min_bid
        });

      } catch (error) {
        console.error("Error fetching auction items:", error);
      }
    };

    fetchAuctionItems(item_id);
  }, [item_id]);






  const navigate = useNavigate();

  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === "null" || !accessToken) {
      // Set fade out to true after a delay
      const timeout = setTimeout(() => {
        setFadeOut(true);
      }, 1000); // Adjust the delay as needed

      // Clear the timeout on component unmount
      return () => clearTimeout(timeout);
    }
  }, []); // Run only once when the component mounts



  useEffect(() => {
    // Navigate after the fade-out effect completes
    if (fadeOut) {
      const timeout = setTimeout(() => {
        navigate(`/login?item_id=${item_id}`); // Redirecting to the login page
      }, 500); // Adjust the timeout to match the duration of the fade-out animation
      return () => clearTimeout(timeout);
    }
  }, [fadeOut, item_id, countdown]);

  return (
    <div className={fadeOut ? "fade-out" : ""}>
      {/* Navbar */}
      <Navbar />

      {/* Start */}
      <section className="bg-item-detail d-table w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sticky-bar">
                <img
                  src={image_url}
                  className="img-fluid rounded-md shadow"
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ms-lg-5">
                <div className="title-heading">
                  <h4 className="h3 fw-bold mb-0">{title}</h4>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-4 pt-2">
                    <h6>Current Bid</h6>
                    <h4 className="mb-0">
                      {bids.length === 0 ? "none" : bids[0].bid_amount} CAD
                    </h4>
                  </div>

                  <div className="col-md-6 mt-4 pt-2">
                    <h6>Auction Ending In</h6>
                    <Countdown
                      date={countdown}
                      renderer={({ days, hours, minutes, seconds }) => (
                        <span>
                          {days}:{hours}:{minutes}:{seconds}
                        </span>
                      )}
                    />
                  </div>

                  <div className="col-12 mt-4 pt-2">
                    <a
                      href="#"
                      className="btn btn-l btn-pills btn-primary me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#NftBid"
                    >
                      <i className="mdi mdi-gavel fs-5 me-2"></i> Place a Bid
                    </a>
                    <a
                      href="#"
                      className="btn btn-l btn-pills btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#NftBuynow"
                    >
                      <i className="mdi mdi-cart fs-5 me-2"></i> Buy Now
                    </a>
                  </div>
                </div>

                <div className="row mt-4 pt-2">
                  <div className="col-12">
                    <ul
                      className="nav nav-tabs border-bottom"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="detail-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#detailItem"
                          type="button"
                          role="tab"
                          aria-controls="detailItem"
                          aria-selected="true"
                        >
                          Details
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="bids-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#bids"
                          type="button"
                          role="tab"
                          aria-controls="bids"
                          aria-selected="false"
                        >
                          Bids
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content mt-4 pt-2" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="detailItem"
                        role="tabpanel"
                        aria-labelledby="detail-tab"
                      >
                        <div
                          className="item-description text-muted"
                          dangerouslySetInnerHTML={{ __html: ItemDescription }}
                        ></div>

                        <h6>Owner</h6>

                        <div className="creators creator-primary d-flex align-items-center">
                          <div className="position-relative">
                            <img
                              src={client14}
                              className="avatar avatar-md-sm shadow-md rounded-pill"
                              alt=""
                            />
                            <span className="verified text-primary">
                              <i className="mdi mdi-check-decagram"></i>
                            </span>
                          </div>

                          <div className="ms-3">
                            <h6 className="mb-0">
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate("/");
                                }}
                                className="text-dark name"
                              >
                                {owner}
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="bids"
                        role="tabpanel"
                        aria-labelledby="bids-tab"
                      >
                        <div>
                          {bids.map((bid, index) => {
                            // Calculate time difference in milliseconds
                            const currentTime = Date.now();
                            const bidTime = new Date(bid.timestamp).getTime();
                            const timeDifference =
                              currentTime - bidTime + 28800000;

                            // Convert time difference to minutes or hours
                            const minutesDifference = Math.floor(
                              timeDifference / (1000 * 60)
                            );
                            const hoursDifference = Math.floor(
                              timeDifference / (1000 * 60 * 60)
                            );

                            // Determine whether to display time in minutes or hours
                            const timeAgo =
                              hoursDifference > 0
                                ? `${hoursDifference} hour(s) ago`
                                : `${minutesDifference} minute(s) ago`;

                            return (
                              <div
                                className="creators creator-primary d-flex align-items-center"
                                key={index}
                              >
                                <div className="position-relative">
                                  <img
                                    src={wine1}
                                    className="avatar avatar-md-sm shadow-md rounded-pill"
                                    alt=""
                                  />
                                </div>

                                <div className="ms-3">
                                  <h6 className="mb-0">
                                    {bid.bid_amount} CAD{" "}
                                    <span className="text-muted">by</span>{" "}
                                    {bid.nickname}
                                  </h6>
                                  <small className="text-muted">
                                    {timeAgo}
                                  </small>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End */}

      {/* Place Bid Modal */}
      <div
        className="modal fade"
        id="NftBid"
        aria-hidden="true"
        aria-labelledby="bidtitle"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="modal-header">
              <h5 className="modal-title" id="bidtitle">
                Place a Bid
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label fw-bold">
                        Your Bid Price <span className="text-danger">*</span>
                      </label>
                      <input
                        name="bid"
                        id="bid"
                        type="number"
                        className="form-control"
                        value={formData.bid_amount || ''}
                        onChange={(e) => {
                          // Since you're updating only one field in formData, you don't need to spread the entire object.
                          setFormData({ ...formData, bid_amount: e.target.value });
                        }}
                      />

                    </div>
                  </div>
                </div>
              </form>

              <div className="pt-3">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> You must bid at least:</p>
                  <p className="text-primary"> {formData.min_bid_amount} CAD </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> Total bid amount:</p>
                  <p className="text-primary mb-0">
                    {" "}
                    {formData.bid_amount} CAD{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-pills btn-primary"
                data-bs-target="#placebid"
                data-bs-toggle="modal"
                onClick={async (e) => {
                  e.preventDefault();

                  if (formData.bid_amount >= formData.min_bid_amount) {
                    try {
                      // Retrieve the bearer token from local storage
                      const accessToken = localStorage.getItem("accessToken");
  
                      const body = { ...formData };
  
                      // Make the API call
                      const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/make-bid`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                          },
                          body: JSON.stringify(body),
                        }
                      );

  
                      if (response.ok) {
                        // Handle success, e.g., show a success message
                        console.log("Bid submitted successfully");
                        setBidSuccess(true);
  
                        await updateBids();
                      } else {
                        // Handle API error
                        console.error("Failed to submit bid");
                      }
                    } catch (error) {
                      // Handle network error
                      console.error("Network error:", error);
                    }
                  } else {
                    setBidSuccess(false);
                  }


                }}
              >
                <i className="mdi mdi-gavel fs-5 me-2"></i> Place a Bid
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="placebid"
        aria-hidden="true"
        aria-labelledby="bidsuccess"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="modal-header">
              <h5 className="modal-title" id="bidsuccess">
                {bidSuccess ? "Bidding Successful!" : "Your bid didn't meeting minimum requirements"}
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            {bidSuccess &&    <div className="modal-body p-4">
              your bid has been added!
            </div>}

          </div>
        </div>
      </div>
      {/* Place Bid Modal */}

      {/* Buy Now NFt Modal */}
      <div
        className="modal fade"
        id="NftBuynow"
        aria-hidden="true"
        aria-labelledby="buyNft"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNft">
                Checkout
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label fw-bold">
                        Buy Now Price <span className="text-danger">*</span>
                      </label>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                        value={BuyNowPrice}
                      />
                    </div>
                  </div>
                  {/*end col*/}
                </div>
              </form>

              <div className="mt-4">
                <button
                  className="btn btn-pills btn-primary w-100"
                  data-bs-target="#buyNftSuccess"
                  data-bs-toggle="modal"
                >
                  <i className="mdi mdi-cart fs-5 me-2"></i> Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="buyNftSuccess"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="position-absolute top-0 start-100 translate-middle z-index-1">
              <button
                type="button"
                className="btn btn-icon btn-pills btn-sm btn-light btn-close opacity-10"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body text-center p-4">
              <h3>Congratulations! 🎉</h3>
              <h6 className="text-muted mb-0">
                You successfully purchased{" "}
                <a href="" className="text-reset">
                  <u>{title}</u>
                </a>{" "}
                from Big Sisters of BC Lower Mainland
              </h6>
            </div>
          </div>
        </div>
      </div>
      {/* Buy Now NFt Modal */}
      {/* footer */}
      <Footer />
    </div>
  );
};

export default ItemDetailOne;