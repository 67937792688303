import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import SignUp from './index'

console.log(process.env.REACT_APP_STRIPE_KEY);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeContainer = () => {

  return <Elements stripe={stripePromise}>
    <SignUp />

  </Elements>;
};

export default StripeContainer;