import React, { Component } from "react";
import mobileLogo from '../assets/images/mobile-logo.png';
import '../assets/css/Spinner.css';

class Spinner extends Component {
  render() {
    return (
      <div className="loading-overlay">
        <div className="spinner"></div>
        <div className="loading-text">Loading</div>
        
        <div className="logo-container">
          <img src={mobileLogo} alt="Mobile Logo" className="mobile-logo logo-spinner" />
        </div>
      </div>
    );
  }
}

export default Spinner;
