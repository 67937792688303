import React, { useState } from 'react';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#232323",
      fontSize: "16px",
    },
    invalid: {
      color: "#fa755a",
      fontSize: "16px",
    },
  },
};

const BasicDetails = ({ formData, handleInputChange, error, CardElement }) => {

  return (
    <>
    {formData.promoCode === "99999" ? <h5 className="mb-4">Welcome Apollo, Please Create Your Account</h5> : <h5 className="mb-4">Create Your Account</h5>}
        <div className="row">
        <div className="col-lg-6">
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control"
                id="FirstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                required={true}
              />
              <label htmlFor="FirstName">First Name <span className="required">*</span></label>
              {error?.city && <p className='form-error'>{error?.firstName}</p>}
            </div>
          </div>
          {/* end col */}

          <div className="col-lg-6">
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control"
                id="LastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="LastName"
                required={true}
              />
              <label htmlFor="LastName">Last Name <span className="required">*</span></label>
              {error?.province && <p className='form-error'>{error?.lastName}</p>}
            </div>
          </div>

        <div className="col-lg-12">
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control"
                id="Nickname"
                name="nickname"
                value={formData?.nickname}
                onChange={handleInputChange}
                placeholder="Nickname to display on bids"
              />
              <label htmlFor="Nickname">Nickname</label>
              {error?.nickname && <p className='form-error'>{error?.nickname}</p>}
            </div>
          </div>
          {/* end col */}

        <div className="col-lg-6">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="PromoCode"
                name="promoCode"
                value={formData?.promoCode}
                onChange={handleInputChange}
                placeholder="Promo Code"
              />
              <label htmlFor="PromoCode">Promo Code</label>
              {error?.promoCode && <p className='form-error'>{error?.promoCode}</p>}
            </div>
          </div>
          {/* end col */}

          <div className="col-lg-6">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="PhoneNumber"
                name="phoneNumber"
                value={formData?.phoneNumber}
                onChange={handleInputChange}
                placeholder="Phone Number"
              />
              <label htmlFor="PhoneNumber">Phone Number <span className="required">*</span></label>
              {error?.phoneNumber && <p className='form-error'>{error?.phoneNumber}</p>}
            </div>
          </div>
          {/* end col */}

          <div className="col-lg-12">
            <div className="form-floating mb-2">
              <input
                type="email"
                className="form-control"
                id="RegisterEmail"
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
                placeholder="name@example.com"
              />
              <label htmlFor="RegisterEmail">Login Email <span className="required">*</span></label>
              {error?.email && <p className='form-error'>{error?.email}</p>}
            </div>
          </div>
          {/* end col */}

          <div className="col-lg-12">
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="RegisterPassword"
                name="password"
                value={formData?.password}
                onChange={handleInputChange}
                placeholder="Password"
              />
              <label htmlFor="RegisterPassword">Password <span className="required">*</span></label>
              {error?.password && <p className='form-error'>{error?.password}</p>}
            </div>
          </div>
          {/* end col */}

          <div className="col-lg-12">
          {formData?.promoCode === "12345" ? 
            <p style={{fontSize: "0.8rem"}}>Please fill in your
              information to gain free access to the site. Your credit card will
              be securely stored to enable bids on our auction site.</p> : 
                          <p style={{fontSize: "0.8rem"}}>
                          Gain access to our awesome auction site for $50!
                        </p>}
            <div className="form-floating mb-2">
            <CardElement
                    className="form-control"
                    options={CARD_ELEMENT_OPTIONS}
                    id="CardNumber"
                    name="cardNumber"
                  />

{error?.cardNumber && <p className='form-error'>{error?.cardNumber}</p>}
            </div>
          </div>
          {/* end col */}



          <div className="col-lg-12">
                        <button className="btn btn-primary rounded-md w-100" type="submit">
                          Continue
                        </button>
                      </div>
                      {/* end col */}
        </div>
    </>
  );
};

export default BasicDetails;