import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiCamera } from 'react-icons/fi'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import {
  single, wine9,
  wine1
} from '../../components/imageImport'

const CreateProfile = () => {
  const [accessToken, setAccessToken] = useState('');
  const [activityData, setActivityData] = useState([]);
  const [nickname, setNickname] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchItem = async (item_id) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/brenda/auction-items?item_id=${item_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}` // Include access token in the headers
          }
        });
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          console.error('Failed to fetch auction item:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching auction item:', error);
      }
    };


    const token = localStorage.getItem('accessToken');

    const fetchData = async (token) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/bids`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}` // Include access token in the headers
          }
        });
        if (response.ok) {
          const data = await response.json();
          const newData = [];

          for (const item of data) {
            console.log("SCREAM");
            console.log(item);
            const result = await fetchItem(item.item_id);
            console.log("WHY")
            console.log(result);
            if (result && result.ListingName) {
              item["ListingName"] = result["ListingName"];
            }
            newData.push(item);
          }

          console.log("SCREAM")
          console.log(newData);
          setActivityData(newData); // Update activityData state with the response data

          
          
        } else {
          console.error('Failed to fetch bids:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching bids:', error);
      }
    };
    
    if (token !== "null") {
      setAccessToken(token);
      fetchData(token); // Call the fetchData function

      try {
        fetch(`${process.env.REACT_APP_API_URL}/check-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ access_token: token }) // Include the token in the request body
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch data');
          }
        })
        .then(data => {
          console.log(data);
          const nicknameAttribute = data.UserAttributes.find(attribute => attribute.Name === "nickname");
          const nickname = nicknameAttribute ? nicknameAttribute.Value : null;
          setNickname(nickname);
          // Handle the response data here
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      navigate("/login");
    }


  }, [setAccessToken, setActivityData]);

/*
const activityData = [
  {
    title: 'Chardonnay',
    author: 'Moon Cursor',
    time: '1 hours ago',
    favorite: '30 CAD Bid on ',
    image: moonCusor,
  },
  {
    title: 'Pino Grigio',
    author: 'Backyard Wineries',
    time: '2 hours ago',
    favorite: '25 CAD Bid on ',
    image: backyard,
  },
  {
    title: 'Merlot',
    author: 'Quail\'s Gate',
    time: '2 hours ago',
    favorite: '11 CAD Bid on ',
    image: quailsGate,
  }
]
*/


  const loadFile = function (event) {
    var image = document.getElementById(event.target.name)
    image.src = URL.createObjectURL(event.target.files[0])
  }

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section className="bg-creator-profile">
        <div className="container">
          <div className="profile-banner">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="d-none"
              onChange={e => loadFile(e)}
            />
            <div className="position-relative d-inline-block">
              <img
                src={single}
                className="rounded-md shadow-sm img-fluid"
                id="profile-banner"
                alt=""
              />
              <label
                className="icons position-absolute bottom-0 end-0"
                htmlFor="pro-banner"
              >

              </label>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col">
              <div className="text-center mt-n80">
                <div className="profile-pic">
                  <div className="position-relative d-inline-block">
                    <img
                      src={wine9}
                      className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                      id="profile-image"
                      alt=""
                    />
                    <label
                      className="icons position-absolute bottom-0 end-0"
                      htmlFor="pro-img"
                    >
                    </label>
                  </div>
                </div>

                <div className="content mt-3">
                  <h5 className="mb-3">{nickname}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row">
            <div className="col-12">
              <ul
                className="nav nav-tabs border-bottom"
                id="myTab"
                role="tablist"
              >
          
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="Activites-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Activites"
                    type="button"
                    role="tab"
                    aria-controls="Activites"
                    aria-selected="true"
                  >
                    Activites
                  </button>
                </li>
              </ul>

              <div className="tab-content mt-4 pt-2" id="myTabContent">
                
                {/* if value select activity */}
                <div
                  className="tab-pane fade show active"
                  id="Activites"
                  role="tabpanel"
                  aria-labelledby="Activites-tab"
                >
                  <div className="row g-4">
                    {activityData?.map(data => {
                      return (
                        <div className="col-12" key={data?.title}>
                          <div className="card activity activity-primary rounded-md shadow p-4">
                            <div className="d-flex align-items-center">
                              <div className="position-relative">
                                <img
                                  src={wine1}
                                  className="avatar avatar-md-md rounded-md shadow-md"
                                  alt=""
                                />

                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div>
                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div>
                              </div>

                              <span className="content ms-3">
                                <a
                                  href=""
                                  onClick={e => e.preventDefault()}
                                  className="text-dark title mb-0 h6 d-block"
                                >
                                  {data?.ListingName}
                                </a>
                                <small className="text-muted d-block mt-1">
                                  {data?.favorite}
                                  <a
                                    href=""
                                    onClick={e => e.preventDefault()}
                                    className="link fw-bold"
                                  >
                                    {data?.nickname}
                                  </a>
                                </small>

                                <small className="text-muted d-block mt-1">
  Made a bid at {data?.timestamp ? new Date(data.timestamp).toLocaleString('en-US', {timeZone: 'America/Los_Angeles'}) : ''}
</small>

                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Home */}
      {/* footer */}
      <Footer />

    </>
  )
}

export default CreateProfile
