import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { logo, opening } from "../../components/imageImport";
import "../../assets/css/Login.css";
import CreditCardDetails from "./CreditCardDetails";
import BasicDetails from "./BasicDetails";
import AccessCode from "./AccessCode";
import Spinner from "../../components/Spinner";
import AdminLogo from "../../components/AdminLogo";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const SignUp = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [state, setState] = useState(1);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    nickname: "",
    promoCode: "",
    phoneNumber: "",
    email: "",
    password: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Retrieve promoCode from local storage
    const promoCode = localStorage.getItem('promoCode');
    if (promoCode && promoCode !== 'null') {
      const updatedFormData = {...formData};
      updatedFormData["promoCode"] = promoCode;

      if (promoCode === "99999") {
        updatedFormData["firstName"] = "Apollo";
        updatedFormData["lastName"] = "Chung";
        updatedFormData["addressLine1"] = "198 Aquarius Mews";
        updatedFormData["city"] = "Vancouver";
        updatedFormData["province"] = "BC";
        updatedFormData["postalCode"] = "V6Z 2Y4";
        updatedFormData["country"] = "Canada";
        updatedFormData["phoneNumber"] = "7782376055";
        updatedFormData["email"] = "chunga@me.com";
        updatedFormData["isFree"] = true;
      } else if (promoCode === "11111") {
        updatedFormData["firstName"] = "Nathania";
        updatedFormData["lastName"] = "Fung";
        updatedFormData["addressLine1"] = "10771 Athabasca Drive";
        updatedFormData["city"] = "Richmond";
        updatedFormData["province"] = "BC";
        updatedFormData["postalCode"] = "V7A 4Z7";
        updatedFormData["country"] = "Canada";
        updatedFormData["phoneNumber"] = "(604) 839-7343";
        updatedFormData["email"] = "nathania.y.fung@gmail.com";
        updatedFormData["isFree"] = true;
      } else if (promoCode === "22222") {
        updatedFormData["firstName"] = "Marni";
        updatedFormData["lastName"] = "Weinstein";
        updatedFormData["addressLine1"] = "1-1042 Adderley Street";
        updatedFormData["city"] = "North Vancouver";
        updatedFormData["province"] = "BC";
        updatedFormData["postalCode"] = "V7L 1T3";
        updatedFormData["country"] = "Canada";
        updatedFormData["phoneNumber"] = "(778) 558-8795";
        updatedFormData["email"] = "mweinstein@bigsisters.bc.ca";
        updatedFormData["isFree"] = true;
      } else if (promoCode === "33333") {
        updatedFormData["firstName"] = "Marley";
        updatedFormData["lastName"] = "Stevens";
        updatedFormData["addressLine1"] = "113-1420 E 7th Avenue";
        updatedFormData["city"] = "Vancouver";
        updatedFormData["province"] = "BC";
        updatedFormData["postalCode"] = "V5N 1R8";
        updatedFormData["country"] = "Canada";
        updatedFormData["phoneNumber"] = "(778) 321-7071";
        updatedFormData["email"] = "mstevens@bigsisters.bc.ca";
        updatedFormData["isFree"] = true;
      }

      setFormData(updatedFormData);

      // Remove promoCode from local storage
      localStorage.removeItem('promoCode');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (state === 1) {
      const validateFormResult = await validateForm();

      if (!validateFormResult) {
        setIsLoading(false);
        return;
      }

      console.log(formData);


      const registerResult = await register();

      if (!registerResult) {
        setIsLoading(false);
        return;
      }

      const createResult = await createStripeAccount();

      if (!createResult) {
        await deleteUser();
        setIsLoading(false);
        return;
      }


      setState(2);
    } else if (state === 2) {
      if (!formData.accessCode || formData.accessCode.length < 6) {
        setError({"accessCode": "Access code should be 6 digits"})
        setIsLoading(false);
        return;
      }

      const confirmRegistrationResult = await confirmRegistration();

      if (!confirmRegistrationResult) {
        setError({"accessCode": "Wrong access code provided"})
        setIsLoading(false);
        return
      }
      setState(3);
    } else {
      const addressResult = await validateAddressForm();

      if (!addressResult) {
        setIsLoading(false);
        return;
      }

      await finalizeAccount();
      navigate("/");
    }

    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

const resetCode = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  const phoneNumber = await getCleanedPhoneNumber();

  const body = {
    username: formData.email,
    phone_number: `+1${phoneNumber}`
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-code`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  setIsLoading(false);
}

const deleteUser = async () => {
  const body = {
    username: formData.email,
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}/delete-user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

}

  const createStripeAccount = async () => {
    if (!stripe || !elements) {
      setError({cardNumber: "Please try again"})
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    try {
      const body = {
        email: formData.email, // Assuming email is provided in formData
        phone: formData.phoneNumber, // Assuming firstName and lastName are provided in formData
        nickname: formData.nickname ? formData.nickname : formData.firstName
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }

      const responseData = await response.json();
      console.log(responseData);
      const customerID = responseData.customerID

      // Step 2: Attach a payment method to the customer
      const { error: attachError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

      if (attachError) {
        setError({ cardNumber: "Credit card failed, please try another card" });
        return false;
      }

      const body2 = {
        customerID: customerID,
        paymentMethodID: paymentMethod.id,
        amount: 50,
        description: "Big Sisters Auction Site Access",
        isFree: formData?.promoCode === "12345"
      }

      const response2 = await fetch(`${process.env.REACT_APP_API_URL}/payments/attach`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body2),
      });

      if (!response2.ok) {
        throw new Error("Failed to fetch");
      }

      setFormData({ ...formData, customerID: customerID });
    } catch (error) {
      console.log(error);
      setError({ cardNumber: "Credit card failed, please try another card" });
      return false;
    }

    return true;
  };

  const validateForm = async () => {
    const error = {};
    if (!formData || !formData.lastName) {
      error["lastName"] = "Last Name is required";
    }

    if (!formData || !formData.firstName) {
      error["firstName"] = "First Name is required";
    }

    if (!formData || !formData.email) {
      error["email"] = "Email is required";
    }

    if (!formData || !formData.password) {
      error["password"] = "Password is required";
    } else if (formData.password.length < 6) {
      error["password"] = "Password must be at least length 6";
    }

    if (!formData || !formData.phoneNumber) {
      error["phoneNumber"] = "Phone number is required";
    }

    if (Object.keys(error).length > 0) {
      setError(error);
      return false;
    }

    let phoneNumber = await getCleanedPhoneNumber();

    if (phoneNumber.length !== 10) {
      setError({
        phoneNumber:
          "Please enter a valid North American phone number in the format XXX-XXX-XXXX",
      });
      return false;
    }

    return true;
  };

  const getCleanedPhoneNumber = async () => {
    let phoneNumber = formData.phoneNumber.replace(/\D/g, ""); // Extract digits

    if (phoneNumber.length === 11 && phoneNumber.startsWith("1")) {
      phoneNumber = phoneNumber.slice(1);
    }

    return phoneNumber;
  }

  const validateAddressForm = async () => {
    const error = {};


    if (!formData || !formData.addressLine1) {
      error["addressLine1"] = "Address is required";
    }

    if (!formData || !formData.city) {
      error["city"] = "City is required";
    }

    if (!formData || !formData.province) {
      error["province"] = "Province is required";
    }

    if (!formData || !formData.country) {
      error["country"] = "Country is required";
    }

    if (!formData || !formData.postalCode) {
      error["postalCode"] = "Postal Code is required";
    }

    if (!formData.acceptTerms) {
      error["acceptTerms"] = "Need to accept terms";
    }

    if (Object.keys(error).length > 0) {
      setError(error);
      return false;
    }

    return true;
  };


  const register = async () => {
    console.log("SCREAM");
    console.log(formData);
    const phoneNumber = await getCleanedPhoneNumber();
    const body = {
      username: formData.email,
      password: formData.password,
      phone_number: `+1${phoneNumber}`,
      nickname: formData.nickname ? formData.nickname : formData.firstName,
      customerID: formData.customerID,
    };

    console.log(body);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      return false;
    }

    const data = await response.json(); // Await the JSON parsing

    if (data.error) {
      setError({"email": data.error});
      return false;
    }


    return true;
  };

  const confirmRegistration = async () => {
    const body = {
      username: formData.email,
      confirmation_code: formData.accessCode,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/confirm-registration`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true;
  };

  const finalizeAccount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        // Handle failed login
        console.error("Address update failed");
      }
    } catch (error) {
      console.error("Error updating address:", error);
    }

    await login();
  };

  const login = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: formData.email,
          password: formData.password,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        // Store access token in local storage
        localStorage.setItem("accessToken", responseData.token);
        // Handle successful login, e.g., redirect to dashboard
        navigate("/");
      } else {
        // Handle failed login
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  }

  return (
    <>
      <div className="back-to-home">
        <a
          href=""
          onClick={(e) => e.preventDefault()}
          className="back-button btn btn-pills btn-sm btn-icon btn-primary"
        >
          <FiArrowLeft className="icons" />
        </a>
      </div>

      {/* Hero Start */}
      <section className="position-relative">
        <div className="bg-video-wrapper">
          <video autoPlay loop muted playsInline className="bg-video">
            <source src={opening} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="bg-overlay bg-linear-gradient-2"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                <AdminLogo />

                {/* Start Content */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form onSubmit={handleSubmit}>
                      {state === 1 && (
                        <BasicDetails
                          formData={formData}
                          handleInputChange={handleInputChange}
                          error={error}
                          CardElement={CardElement}
                        />
                      )}
                      {state === 2 && (
                        <AccessCode
                          formData={formData}
                          handleInputChange={handleInputChange}
                          error={error}
                          resetCode={resetCode}
                        />
                      )}
                      {state === 3 && (
                        <CreditCardDetails
                          formData={formData}
                          handleInputChange={handleInputChange}
                          error={error}
                        />
                      )}

                      <div className="col-12 text-center mt-4">
                        <small>
                          <span className="text-muted me-2">
                            Already have an account ?{" "}
                          </span>{" "}
                          <a
                            href="/login"
                            onClick={(e) => {
                              e.preventDefault();
                              navigate("/login");
                            }}
                            className="text-dark fw-bold"
                          >
                            Sign in
                          </a>
                        </small>
                      </div>

                      {/* end row */}
                    </form>
                  </div>
                </div>
                {/* End Content */}

                {/* Start Footer */}
                <div className="text-center">
                  <small className="mb-0 text-light title-dark">
                    © {new Date().getFullYear()} Big Sisters of BC Lower
                    Mainland.
                  </small>
                </div>
                {/* End Footer */}
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      {/* Hero End */}
      {isLoading && <Spinner />}
    </>
  );
};

export default SignUp;