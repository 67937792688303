import React from 'react';
import logo from '../assets/images/lockup.png'; // Import your logo image

class Logo extends React.Component {
  render() {
    return (
      <div className="text-center">
        <a href="/">
          <img src={logo} alt=""  style={{height: "200px"}} />
        </a>
      </div>
    );
  }
}

export default Logo;
