import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { wine9, lightLogo, logoDark, character } from "../imageImport";
import "../../assets/css/Login.css";

const Navbar = () => {
  const [accessToken, setAccessToken] = useState(false);
  const [nickname, setNickname] = useState(""); // State for nickname
  const location = useLocation();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState([])
  const url = useMemo(() => location?.pathname === "/blog-detail", []);
  const templatePage = [
    "/become-creator",
    "/creator-profile",
    "/item-detail-one",
    "/index-two",
    "/index-four",
    "/index-five",
    "/index-five-rtl",
    "/index-four-rtl",
    "/index-two-rtl",
  ];
  const becomeUrl = templatePage.includes(location?.pathname);

  const toggleSwitcher = () => {
    var i = document.getElementById("style-switcher");
    if (i) {
      if (i.style.left === "-189px") {
        i.style.left = "0px";
      } else {
        i.style.left = "-189px";
      }
    }
  };


  const mobileHandler = (e, panel) => {
    e.preventDefault()
    const dataIndex = mobile?.findIndex(data => data === panel)
    if (dataIndex === -1) {
      setMobile([...mobile, panel])
    } else {
      const updateRecord = mobile?.filter((data, index) => index !== dataIndex)
      setMobile(updateRecord)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token !== "null") {
      setAccessToken(token);

      try {
        fetch(`${process.env.REACT_APP_API_URL}/check-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ access_token: token }) // Include the token in the request body
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch data');
          }
        })
        .then(data => {
          console.log(data);
          const nicknameAttribute = data.UserAttributes.find(attribute => attribute.Name === "nickname");
          const nickname = nicknameAttribute ? nicknameAttribute.Value : null;
          setNickname(nickname);
          // Handle the response data here
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }, []);

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };

  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var immediateParent = getClosest(matchingMenuItem, "li");
        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(matchingMenuItem, ".parent-menu-item");
        if (parent) {
          parent.classList.add("active");
          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }
          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  };

  const toggleMenu = () => {
    document.getElementById("isToggle").classList.toggle("open");
    var isOpen = document.getElementById("navigation");
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  };
  return (
    <>
      {/* Navbar STart */}
      <header
        id="topnav"
        className={`defaultscroll sticky ${url && "gradient"}`}
      >
        <div className="container">
          {/* Logo Start*/}
          <a
            className="logo"
            href="/index"
            onClick={(e) => {
              e.preventDefault();
              navigate("/index");
              setTimeout(() => {
                activateMenu();
                toggleSwitcher(false);
              }, 1000);
            }}
          >
            <span className="">
              <img
                src={logoDark}
                height="40"
                className={becomeUrl ? "logo-light-mode" : "l-dark"}
                alt=""
              />
              <img
                src={lightLogo}
                height="40"
                className={becomeUrl ? "logo-dark-mode" : "l-light"}
                alt=""
              />
            </span>
            {}
          </a>
          {/* Logo end*/}

          {/* Mobile */}
          <div className="menu-extras">
            <div className="menu-item">
              {/* Mobile menu toggle*/}
              <a
                className="navbar-toggle"
                id="isToggle"
                onClick={(e) => {
                  e.preventDefault();
                  toggleMenu();
                }}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              {/* End mobile menu toggle*/}
            </div>
          </div>
          {/* Mobile */}

          {/*Login button Start*/}
          <ul className="buy-button list-inline mb-0">
            <li className="list-inline-item mb-0">
              <div className="dropdown dropdown-primary">

                  {accessToken ?               
                                  <button
                                  type="button"
                                  className="btn btn-pills dropdown-toggle p-0 shadow-border"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                  
                  <img
                    src={wine9}
                    className="rounded-pill avatar avatar-sm-sm"
                    alt=""
                  />
                                  </button>
                  :                 
                  
<a
  href="/"
  onClick={e => {
    e.preventDefault()
    navigate('/login')
  }}
  className="sub-menu-item"
  style={{ color: '#2dccd3' }}
>
  SIGN IN
</a>

                  }




                <div
                  className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 pb-3 pt-0 overflow-hidden rounded"
                  style={{ minWidth: 200 }}
                >
                  <div className="position-relative">
                    <div className="pt-5 pb-3 bg-gradient-primary"></div>
                    <div className="px-3">
                      <div className="d-flex align-items-end mt-n4">
                        <img
                          src={wine9}
                          className="rounded-pill avatar avatar-md-sm img-thumbnail shadow-md"
                          alt=""
                        />
                        <h6 className="text-dark fw-bold mb-0 ms-1">
                          {nickname ? nickname : "Auctioneer"}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <a
                      className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                      href="/profile"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile");
                      }}
                    >
                      <span className="mb-0 d-inline-block me-1">
                        <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
                      </span>{" "}
                      Profile
                    </a>

                    <div className="dropdown-divider border-top"></div>
                    <a
                      className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                      href={accessToken ? "/login" : "/signup"}
                      onClick={(e) => {
                        e.preventDefault();
                        const accessToken = localStorage.getItem("accessToken")
                        localStorage.setItem("accessToken", null);

                        setTimeout(() => {
                          activateMenu();
                          toggleSwitcher(false);
                        }, 1000);

                        console.log(accessToken);
                        if (accessToken) {
                          navigate("/login");
                        } else {
                          navigate("/signup");
                        }
                        
                      }}
                    >
                      <span className="mb-0 d-inline-block me-1">
                        <i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i>
                      </span>{" "}
                      {accessToken ? "Logout" : "Login"}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          {/*Login button End*/}



          <div id="navigation">
            {/* Navigation Menu*/}
            <ul
              className={`navigation-menu nav-left`}
            >
              <li>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                      activateMenu()
                      toggleSwitcher(false)
                    }, 1000)
                    navigate('/')
                  }}
                  className="sub-menu-item"
                >
                  {'             '}
                </a>
              </li>

              <li>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                      activateMenu()
                      toggleSwitcher(false)
                    }, 1000)
                    navigate('/')
                  }}
                  className="sub-menu-item"
                >
                  {' '}
                  Category 1
                </a>
              </li>

              <li>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                      activateMenu()
                      toggleSwitcher(false)
                    }, 1000)
                    navigate('/')
                  }}
                  className="sub-menu-item"
                >
                  Category 2
                </a>
              </li>


              <li>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                      activateMenu()
                      toggleSwitcher(false)
                    }, 1000)
                    navigate('/')
                  }}
                  className="sub-menu-item"
                >
                  Category 3
                </a>
              </li>


            </ul>
            {/*end navigation menu*/}
          </div>
          {/*end navigation*/}
        </div>
        {/*end container*/}
      </header>
      {/*end header*/}
      {/* Navbar End */}
    </>
  );
};

export default Navbar;