import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { tns } from "tiny-slider/src/tiny-slider";
import Choices from "choices.js";
import Countdown from "react-countdown";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom"; // Assuming you are using React Router for navigation

import {
  opening,
  grapejuice,
} from "../../components/imageImport";

const DarkVersionFour = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [accessToken, setAccessToken] = useState("");
  const [auctionItems, setAuctionItems] = useState([]);
  const [masterAuctionItems, setMasterAuctionItems] = useState([]);
  const [currentBids, setCurrentBids] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchField, setSearchField] = useState("");


  const queryParams = new URLSearchParams(location.search);
  const promoCode = queryParams.get("promo_code");
  const countdown = new Date("2024-04-10T00:00:00");

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token !== null) {
      setAccessToken(token);
    }
    

    // Store promoCode to local storage if it exists
    if (promoCode) {
      localStorage.setItem("promoCode", promoCode);
    }

        // Fetch auction items from the API
        const fetchTags = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/brenda/tags`
            );
    
            if (!response.ok) {
              throw new Error("Failed to fetch tags");
            }
    
            const data = await response.json();
    
            return data;
          } catch (error) {
            console.error("Error fetching auction items:", error);
          }
        };

    // Fetch auction items from the API
    const fetchAuctionItems = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/brenda/auction-items`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch auction items");
        }

        const data = await response.json();

        // Iterate through each record in data and call getImageUrl on each record
        const imageURLPromises = data.map(async (record) => {
          record.item_id = record.ID;
          const imageUrl = await getImageUrl(record.Image1); // Assuming Image1 is the property containing the filename
          const bids = await getBidsByItem(record.item_id)
          return { ...record, image_url: imageUrl, bids: bids };
        });

        // Wait for all getImageUrl calls to resolve
        const auctionItemsWithImageUrls = await Promise.all(imageURLPromises);
        const allTags = await fetchTags();

        setAuctionItems(auctionItemsWithImageUrls); // Update state with fetched auction items
        setMasterAuctionItems(auctionItemsWithImageUrls);

        // Extract tags from auction items and create a set of all tags
        const allAuctionItemTags = new Set();
        auctionItemsWithImageUrls.forEach(item => {
            item.Tags.forEach(tag => {
                allAuctionItemTags.add(tag);
            });
        });

        // Find matching IDs from allTags
        const matchingTagIds = [];
        allTags.forEach(tag => {
          allAuctionItemTags.forEach(tagID => {
            if (tag.ID.toString() === tagID.toString()) {
              matchingTagIds.push(tag);
            }
          })
        });


        setTags(matchingTagIds);

      } catch (error) {
        console.error("Error fetching auction items:", error);
      }
    };

    fetchAuctionItems();
  }, [promoCode, setAuctionItems]);

  const handleClick = (e, item_id) => {
    e.preventDefault();

    navigate(`/item-detail-one?item_id=${item_id}`);
  };

  const handleSelectionChange = (event) => {
    const selection = event.target.value;

    if (selection !== "-1") {
    // Filter the master auction items based on the selected value
    const filteredItems = masterAuctionItems.filter(item =>{
      console.log(item.Tags);
      return item.Tags.includes(selection.toString()) // Convert the selected value to integer if necessary
    }

    );

    // Perform any action with the filtered items here, such as updating the state
    // For example:
    setAuctionItems(filteredItems);
    } else {
      setAuctionItems(masterAuctionItems);
    }



  };

  const handleSearch = (e) => {

    const searchTerm = e.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive comparison

    if (searchTerm.length > 0) {
      const filteredItems = masterAuctionItems.filter(item => {
        // You can customize this condition according to your requirements.
        // This example checks if the item's name or description contains the search term.
        return item.ListingName.toLowerCase().includes(searchTerm) || item.ItemDescription.toLowerCase().includes(searchTerm);
      });
  
      setAuctionItems(filteredItems); // Update auctionItems with the filtered list
    } else {
      setAuctionItems(masterAuctionItems);
    }

  };

  const getImageUrl = async (filename) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/brenda/auction-items/file/${filename}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch auction items");
      }

      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error("Error fetching auction items:", error);
    }
  };

  const getBidsByItem = async (item_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/bids?item_id=${item_id}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch auction items");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching auction items:", error);
    }
  };

  useEffect(() => {
    new Choices("#choices-criteria");
    var singleCategorie = document.getElementById("choices-type");
    if (singleCategorie) {
      var singleCategories = new Choices("#choices-type");
    }
  }, []);
  useEffect(() => {
    document.getElementById("theme-opt").href = "./css/style.min.css";
  }, [location?.pathname]);

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section className="bg-half-100 d-table w-100 pb-0">
        <div className="container position-relative" style={{ zIndex: 1 }}>
          <div
            className="rounded-md shadow-sm position-relative overflow-hidden"
            style={{
              paddingTop: "100px",
              paddingBottom: "50px",
              paddingLeft: "0",
              paddingRight: "0",
            }}
          >
            <div className="bg-video-wrapper">
              <video autoPlay loop muted playsInline>
                <source src={opening} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="row justify-content-center mb-5">
              <div className="col-12">
                <div className="title-heading text-center px-4">
                  <img src={grapejuice} style={{ height: "100px" }} />
                  <br />
                  <h4 className="display-6 text-white title-dark fw-medium mb-3">
                    <i className="uil uil-clock"></i>{" "}
                    <Countdown
                      date={countdown}
                      renderer={({ days, hours, minutes, seconds }) => (
                        <span>
                          {days}:{hours}:{minutes}:{seconds}
                        </span>
                      )}
                    />
                  </h4>
                  <p className="text-white title-dark mb-0">
                    Big Sisters of BC Lower Mainland GrapeJuice 2024 Auction
                    Site
                  </p>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Home */}

      {/* Start */}
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="features-absolute">
                <div className="row justify-content-center" id="reserve-form">
                  <div className="col-xl-10 mt-lg-5">
                    <div className="card bg-white feature-top border-0 shadow rounded p-3">
                      <form>
                        <div className="registration-form text-dark text-start">
                          <div className="row g-lg-0">
                            <div className="col-lg-3 col-md-6">
                              <div className="filter-search-form position-relative filter-border">
                                <i className="uil uil-search icons"></i>
                                <input
                                  name="name"
                                  type="text"
                                  id="search-keyword"
                                  className="form-control filter-input-box bg-light border-0"
                                  placeholder="Search your keywords"
                                  onChange={handleSearch}
                                />
                              </div>
                            </div>
                            {/*end col*/}

                            <div className="col-lg-3 col-md-6 mt-3 mt-md-0">
                              <div className="filter-search-form position-relative filter-border">
                                <i className="uil uil-usd-circle icons"></i>
                                <select
                                  className="form-select"
                                  data-trigger
                                  name="choices-criteria"
                                  id="choices-criteria"
                                  aria-label="Default select example"
                                  defaultValue={"Auction Product"}
                                >
                                  <option value="1">Auction Product</option>
                                  <option value="2">Buy Now</option>
                                  <option value="3">Bid Now</option>
                                </select>
                              </div>
                            </div>
                            {/*end col*/}

                            <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                              <div className="filter-search-form position-relative filter-border">
                                <i className="uil uil-window icons"></i>
                                <select
                                  className="form-select "
                                  data-trigger
                                  name="choices-type"
                                  id="choices-type"
                                  aria-label="Default select example"
                                  defaultValue={"Art"}
                                  onChange={handleSelectionChange}
                                >
                                  <option value="-1">
                                    - Clear Filter -
                                  </option>
                                  <option value="14">
                                    Accomodations
                                  </option>
                                  <option value="15">Entertainment</option>
                                  <option value="16">FNB</option>
                                  <option value="17">Packages</option>
    
                                </select>
                              </div>
                            </div>
                            {/*end col*/}

                            <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                              <input
                                type="submit"
                                id="search"
                                name="search"
                                style={{ height: 60 }}
                                className="btn btn-primary rounded-md searchbtn submit-btn w-100"
                                value="Search"
                              />
                            </div>
                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </div>
                        {/*end container*/}
                      </form>
                    </div>
                  </div>
                  {/*ed col*/}
                </div>
                {/*end row*/}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        <div className="container mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">
            {auctionItems.map((data) => {
              return (
                <div className="col" key={data?.ListingName}>
                  <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                    <div
                      className="nft-image rounded-md mt-3 position-relative overflow-hidden"
                      onClick={(e) => handleClick(e, data?.ID)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={data?.image_url} className="img-fluid" alt="" />
                    </div>

                    <div className="card-body content position-relative p-0 mt-3">
                      <a
                        href="/item-detail-one"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/item-detail-one?item_id=${data?.ID}`);
                        }}
                        className="title text-dark h6"
                      >
                        {data?.ListingName}
                      </a>

                      <div className="d-flex justify-content-between mt-2">
                        <small className="rate fw-bold">
                        {data?.bids && data?.bids?.length === 0 ? data.MinimumBid : data?.bids[0].bid_amount} CAD
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End */}
      {/* footer */}
      <Footer />
    </>
  );
};

export default DarkVersionFour;