import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import AdminLogo from '../../components/AdminLogo'
import '../../assets/css/Login.css'
import Spinner from "../../components/Spinner";
import {
  opening
} from "../../components/imageImport";

function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [tempPassword, setTempPassword] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (step === 1) {
      await handleTempReset(e);
    } else if (step === 2) {
      await handleResetPassword(e);
    }
    setIsLoading(false);
  };

  const handleTempReset = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email }),
      });
      if (response.ok) {
        const responseData = await response.json();

        setStep(2);

      } else {
        // Handle failed login
        console.error('Reset Password failed');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-admin-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, tempPassword: tempPassword, password: password }),
      });
      if (response.ok) {
        const responseData = await response.json();

        navigate('/login');
      } else {
        // Handle failed login
        console.error('Reset Password failed');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  return (
    <>
      <div className="back-to-home">
        <a
          href=""
          onClick={e => e.preventDefault()}
          className="back-button btn btn-pills btn-sm btn-icon btn-primary"
        >
          <FiArrowLeft className="icons" />
        </a>
      </div>

      {/*  Hero Start */}
      <section className="position-relative">
        <div className="bg-video-wrapper">
        <video autoPlay loop muted playsInline  className="bg-video">
                <source src={opening} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
        </div>
        <div className="bg-overlay bg-linear-gradient-2"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
              <AdminLogo />

                {/*  Start Content */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form onSubmit={handleReset}>
                      <h5 className="mb-3">Reset Your Password</h5>
                      {step === 1 ? 
                                            <p className="text-muted">
                                            Please enter your email address. You'll receive a temporary password in your email that'll be used to create a new password.
                                          </p>:
                                                                <p className="text-muted">
                                                                Your temporary password has been sent to your email. To create a new permanent password, fill in the password fields below.
                                                              </p>
                    }


                      <div className="row">
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              placeholder="name@example.com"
                              value={email}
                              onChange={e=> setEmail(e.target.value)}
                            />
                            <label htmlFor="floatingInput">Email address</label>
                          </div>
                        </div>
                        {/* end col*/}

                        {step === 2 && <>
                          <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control"
                              id="tempPassword"
                              placeholder="name@example.com"
                              value={tempPassword}
                              onChange={e=> setTempPassword(e.target.value)}
                            />
                            <label htmlFor="tempPassword">Temporary Password</label>
                          </div>
                        </div>
                        {/* end col*/}

                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              placeholder="name@example.com"
                              value={password}
                              onChange={e=> setPassword(e.target.value)}
                            />
                            <label htmlFor="password">new Password</label>
                          </div>
                        </div>
                        {/* end col*/}
                        </>}



                        <div className="col-12">
                          <button
                            className="btn btn-primary rounded-md w-100"
                            type="submit"
                          >
                            Send
                          </button>
                        </div>
                        {/* end col*/}

                        <div className="col-12 text-center mt-4">
                          <small>
                            <span className="text-muted me-2">
                              Remember your password ?{' '}
                            </span>
                            <a
                              href="/login"
                              onClick={e => {
                                e.preventDefault()
                                navigate('/login')
                              }}
                              className="text-dark fw-bold"
                            >
                              Sign in
                            </a>
                          </small>
                        </div>
                        {/* end col*/}
                      </div>
                      {/* end row*/}
                    </form>
                  </div>
                </div>
                {/*  End Content */}

                {/*  Start Footer */}
                <div className="text-center">
                <small className="mb-0 text-light title-dark">
                  © <script>document.write(new Date().getFullYear())</script>{' '}
                    Big Sisters of BC Lower Mainland.
                  </small>
                </div>
                {/*  End Footer */}
              </div>
            </div>
            {/* end col*/}
          </div>
          {/* end row*/}
        </div>
        {/* end container*/}
      </section>
      {/* end section*/}
      {/*  Hero End */}

      {isLoading && <Spinner />}
    </>
  )
}

export default ResetPassword
