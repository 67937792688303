import React, { useState } from 'react';

const AccessCode = ({ formData, handleInputChange, error, resetCode }) => {
  return (
    <>
      <h5 className="mb-4">Verify Your Account</h5>
      <div className="row">
        <div className="col-lg-12">
          <p style={{fontSize: "small"}}>A code has been texted to your number {formData.phoneNumber}. Please enter the code you've received below.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-floating mb-2">
            <input
              type="text"
              className="form-control"
              id="AccessCode"
              name="accessCode"
              value={formData?.accessCode}
              onChange={handleInputChange}
              placeholder="Enter the Access Code Just Texted To You"
            />
            <label htmlFor="AccessCode">AccessCode <span className="required">*</span></label>
            {error?.accessCode && <p className='form-error'>{error?.accessCode}</p>}
          </div>
        </div>
        {/* end col */}

        <div className="col-lg-12">
          <button className="btn btn-primary rounded-md w-100" type="submit">
            Verify Account
          </button>
        </div>
        {/* end col */}

        <div className="col-lg-12 mt-2">
          <button className="btn btn-secondary rounded-md w-100" onClick={resetCode}>
            Resend the Code
          </button>
        </div>
        {/* end col */}
      </div>
    </>
  );
};

export default AccessCode;