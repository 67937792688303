import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import AdminLogo from '../../components/AdminLogo'
import Spinner from "../../components/Spinner";
import '../../assets/css/Login.css'
import {
  opening
} from "../../components/imageImport";

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const item_id = queryParams?.get("item_id");

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch('https://dev.api.bigsisters.bc.ca/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, password: password }),
      });

      setIsLoading(false);
      if (response.ok) {
        const responseData = await response.json();
        // Store access token in local storage
        localStorage.setItem('accessToken', responseData.token);
        // Handle successful login, e.g., redirect to dashboard
        if (item_id) {
          navigate(`/item-detail-one?item_id=${item_id}`);
        } else {
          navigate('/');
        }
        
      } else {
        // Handle failed login
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <>


      {/*  Hero Start  */}
      <section className="position-relative">
        <div className="bg-video-wrapper">
        <video autoPlay loop muted playsInline  className="bg-video">
                <source src={opening} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
        </div>
        <div className="bg-overlay bg-linear-gradient-2"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="d-flex flex-column min-vh-100 p-4">
                <AdminLogo />

                {/*  Start Content  */}
                <div className="title-heading text-center my-auto">
                  <div className="form-signin px-4 py-5 bg-white rounded-md shadow-sm">
                    <form  onSubmit={handleLogin}>
                      <h5 className="mb-4">Login to Bid!</h5>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-floating mb-2">
                            <input
                              type="email"
                              className="form-control"
                              id="LoginEmail"
                              placeholder="name@example.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <label htmlFor="LoginEmail">Email Address:</label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control"
                              id="LoginPassword"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <label htmlFor="LoginPassword">Password:</label>
                          </div>
                        </div>
                        {/* end col */}

                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between">
                            <div className="mb-3">
                              <div className="form-check align-items-center d-flex mb-0">
                                <input
                                  className="form-check-input mt-0"
                                  type="checkbox"
                                  value=""
                                  id="RememberMe"
                                />
                                <label
                                  className="form-check-label text-muted ms-2"
                                  htmlFor="RememberMe"
                                >
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <small className="text-muted mb-0">
                              <a
                                href="/reset-password"
                                onClick={e => {
                                  e.preventDefault()
                                  navigate('/reset-password')
                                }}
                                className="text-muted fw-semibold"
                              >
                                Forgot password ?
                              </a>
                            </small>
                          </div>
                        </div>
                        {/* end col */}
                        <div className="col-lg-12">
                          <button
                            className="btn btn-primary rounded-md w-100"
                            type="submit"
                          >
                            Sign in
                          </button>
                        </div>
                        {/* end col */}

                        <div className="col-12 text-center mt-4">
                          <small>
                            <span className="text-muted me-2">
                              Don't have an account ?
                            </span>{' '}
                            <a
                              href="/signup"
                              onClick={e => {
                                e.preventDefault()
                                navigate('/signup')
                              }}
                              className="text-dark fw-bold"
                            >
                              Sign Up
                            </a>
                          </small>
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                    </form>
                  </div>
                </div>
                {/*  End Content  */}

                {/*  Start Footer  */}
                <div className="text-center">
                  <small className="mb-0 text-light title-dark">
                  © <script>document.write(new Date().getFullYear())</script>{' '}
                    Big Sisters of BC Lower Mainland.
                  </small>
                </div>
                {/*  End Footer  */}
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      {/*  Hero End  */}
      {isLoading && <Spinner />}
    </>
  )
}

export default Login
